.password-validate {
        
    /* Style the container for inputs */
    .container {
        background-color: #f1f1f1;
        padding: 20px;
    }
      
    /* The message box is shown when the user clicks on the password field */
    #message {
        background: #f1f1f1;
        color: #000;
        position: relative;
        padding: 20px;
        margin-top: 10px;
    }
      
    #message p {
        padding: 10px 35px;
        font-size: 18px;
    }
      
    /* Add a green text color and a checkmark when the requirements are right */
    .valid {
        color: green;
    }
      
    .valid:before {
        position: relative;
        content: "✔";
    }
      
    /* Add a red text color and an "x" when the requirements are wrong */
    .invalid {
        color: red;
    }
      
    .invalid:before {
        position: relative;      
        content: "✖";
    }
}
