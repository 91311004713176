/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 24, 2022 */



@font-face {
    font-family: 'gothambold';
    src: url('../fonts/gothambold-webfont.eot');
    src: url('../fonts/gothambold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gothambold-webfont.woff2') format('woff2'),
         url('../fonts/gothambold-webfont.woff') format('woff'),
         url('../fonts/gothambold-webfont.ttf') format('truetype'),
         url('../fonts/gothambold-webfont.svg#gothambold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gothamRegular';
    src: url('../fonts/gothambook-webfont.eot');
    src: url('../fonts/gothambook-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gothambook-webfont.woff2') format('woff2'),
         url('../fonts/gothambook-webfont.woff') format('woff'),
         url('../fonts/gothambook-webfont.ttf') format('truetype'),
         url('../fonts/gothambook-webfont.svg#gothambook') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gothamlight';
    src: url('../fonts/gothamlight-webfont.eot');
    src: url('../fonts/gothamlight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gothamlight-webfont.woff2') format('woff2'),
         url('../fonts/gothamlight-webfont.woff') format('woff'),
         url('../fonts/gothamlight-webfont.ttf') format('truetype'),
         url('../fonts/gothamlight-webfont.svg#gothamlight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gothammedium';
    src: url('../fonts/gothammedium-webfont.eot');
    src: url('../fonts/gothammedium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gothammedium-webfont.woff2') format('woff2'),
         url('../fonts/gothammedium-webfont.woff') format('woff'),
         url('../fonts/gothammedium-webfont.ttf') format('truetype'),
         url('../fonts/gothammedium-webfont.svg#gothammedium') format('svg');
    font-weight: normal;
    font-style: normal;

}