// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

:root {

    /* Colors: */
    --unnamed-color-ffffff: #FFFFFF;
    --laranja-principal: #F47A4B;
    --verde-principal: #BEEA53;
    --roxo-principal: #8A36CC;
    --azul-principal: #2B44AF;
    --unnamed-color:#00000080;
}
