// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
@import 'plan.scss';
@import 'password.scss';
@import 'dual-listbox.scss';
@import 'spinner.scss';

// Bootstrap
// @import '~bootstrap/scss/bootstrap';

// MDBootstrap
@import 'mdb/mdb.scss';

// Custom
@import 'components/calendar';
@import 'components/custom';


@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";


