/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    color: #444444;
    font-family: "Roboto";
    background-color: var(--unnamed-color-ffffff);
}

.container {
    max-width: 1170px;
}

a {
    text-decoration: none;
    color: var(--azul-principal);
}

a:hover {
    color: #2b99f8;
    text-decoration: none;
}

.hac {
	background-color: rgb(255, 255, 0);
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: var(--azul-principal);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
}

.back-to-top:hover {
    background: #2194f7;
    color: #fff;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

.fixed-top {
    position: fixed !important;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid var(--azul-principal);
    border-top-color: #bfe0fd;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
    height: 60px;
    padding: 0;
    font-size: 14px;
    transition: all 0.5s;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.8);
    z-index: 996;
}

#topbar.topbar-scrolled {
    top: -60px;
}

#topbar .contact-info a {
    line-height: 0;
    color: rgba(255, 255, 255, 0.8);
    transition: 0.3s;
}

#topbar .contact-info a:hover {
    text-decoration: underline;
}

#topbar .contact-info i {
    color: var(--laranja-principal);
    line-height: 0;
    margin-right: 5px;
}

#topbar .contact-info .phone-icon {
    margin-left: 15px;
}

#topbar .cta {
    background: transparent;
}

#topbar .cta a {
    color: #fff;
    background: var(--laranja-principal);
    padding: 6px 24px 8px 24px;
    display: inline-block;
    transition: 0.3s;
    border-radius: 50px;
}

#topbar .cta a:hover {
    background: #f1a40a;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    background: none;
    transition: all 0.5s;
    z-index: 997;
    height: 70px;
    position: absolute;
    width: 100%
}

#header.header-scrolled {
    background: rgba(5, 87, 158, 0.9);
    top: 0;
    position: fixed;

    .navbar {
        background: none;
    }
}

.carousel-container {
    img {
        position: absolute;
        right: -55px;
        top: -45px;
        max-width: 640px;

        @media screen and (max-width: 768px) {
            display: none;

        }
    }
}

#header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#header .logo a {
    color: #fff;
}

#header .logo img {
    max-height: 40px;
}

.header-inner-pages {
    background: rgba(5, 87, 158, 0.9) !important;
}

.topbar-inner-pages {
    background: rgba(6, 98, 178, 0.9) !important;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/

.navOff {
    .navbar {
        padding: 0;
        box-shadow: none;
        // background-color: var(--azul-principal);
        padding: 8px 0;

        .dropdown-menu {
            background: #dfdfdf;
            padding: 15px 0;

        }

        .dropdown-menu[data-mdb-popper] {
            left: auto;
            right: 0;
        }
    }

    .navbar ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        align-items: center;
    }

    .navbar li {
        position: relative;
    }

    .navbar>ul>li {
        position: relative;
        white-space: nowrap;
        padding: 10px 0 10px 24px;

        .exp {
            background: var(--unnamed-color-ffffff);
            color: var(--laranja-principal);
            padding: 5px 15px;
            border-radius: 30px;

            &::before {
                display: none;
            }

            &:hover {
                background: var(--laranja-principal);
                color: var(--unnamed-color-ffffff);
            }
        }
    }

    .navbar a,
    .navbar a:focus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3px;
        font-size: 14px;
        color: var(--unnamed-color-ffffff);
        white-space: nowrap;
        transition: 0.3s;
        position: relative;
        font-weight: bold;
    }

    .navbar a i,
    .navbar a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
    }

    .navbar>ul>li>a:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -5px;
        left: 0;
        background-color: var(--laranja-principal);
        visibility: hidden;
        width: 0px;
        transition: all 0.3s ease-in-out 0s;
    }

    .navbar a:hover:before,
    .navbar li:hover>a:before,
    .navbar .active:before {
        visibility: visible;
        width: 100%;
    }

    .navbar a:hover,
    .navbar .active,
    .navbar .active:focus,
    .navbar li:hover>a {
        color: #fff;
    }

    .navbar .dropdown ul {
        display: block;
        position: absolute;
        right: 0;
        top: calc(100% + 30px);
        margin: 0;
        padding: 10px 0;
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        background: #fff;
        box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
        transition: 0.3s;
        border-radius: 8px;
    }

    .navbar .dropdown ul li {
        min-width: 200px;
    }

    .navbar .dropdown ul a {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        color: #032e54;
    }

    .navbar .dropdown ul a i {
        font-size: 12px;
    }

    .navbar .dropdown ul a:hover,
    .navbar .dropdown ul .active:hover,
    .navbar .dropdown ul li:hover>a {
        color: var(--azul-principal);
    }

    .navbar .dropdown:hover>ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
        background: #dfdfdf;

        li {
            border-bottom: 1px solid #ccc;
        }
    }

    .navbar .dropdown .dropdown ul {
        top: 0;
        left: calc(100% - 30px);
        visibility: hidden;
    }

    .navbar .dropdown .dropdown:hover>ul {
        opacity: 1;
        top: 0;
        left: 100%;
        visibility: visible;
    }

    @media (max-width: 1366px) {
        .navbar .dropdown .dropdown ul {
            left: -90%;
        }

        .navbar .dropdown .dropdown:hover>ul {
            left: -100%;
        }
    }

    .nav-link {
        &:hover {
            color: var(--laranja-principal);
        }
    }
}


.navbar-expand-lg .navbar-nav .dropdown-menu {
    right: 0;
    background: #ebebeb;
    min-width: 250px;
    top: 40px;
}

a.dropdown-item,
.name-initial p {
    color: #8b8b8b;
    font-size: 13px;
}

.dropdown-item {
    padding: 0.3rem 1rem;
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
    color: var(--laranja-principal);
}

@media (max-width: 768px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(5, 74, 133, 0.9);
    transition: 0.3s;
    z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile>ul>li {
    padding: 0;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #0665b7;
}

.navbar-mobile a:hover:before,
.navbar-mobile li:hover>a:before,
.navbar-mobile .active:before {
    visibility: hidden;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
    color: var(--laranja-principal);
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
    color: var(--laranja-principal);
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 80vh;
    overflow: hidden;
    position: relative;
    background-size: cover;
    position: relative;
    margin-bottom: -90px;
    z-index: 99;
    transition: 0.3s;

}

#hero:before {
    content: "";
    background: linear-gradient(180deg, rgba(24, 38, 98, 1) 0%, rgba(37, 58, 150, 1) 100%);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero:after {
    content: "";
    background: url('../img/textura.png');
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background-position: -730px 174px;
    background-size: 230%;
}

#hero .carousel-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    text-align: center;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    padding: 5%;
}

#hero h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 700;

    @media screen and (min-width: 768px) {
        max-width: 600px;
    }

    span {
        color: var(--verde-principal);
    }
}

#hero p {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    color: #fff;
    text-align: left;
    width: 100%;
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
    width: 10%;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
}

#hero .btn-get-started {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background-color: var(--verde-principal);
    color: var(--azul-principal);
    font-weight: bold;

}

#hero .btn-get-started:hover {
    background: var(--laranja-principal);
    color: #fff;
    text-decoration: none;
}

@media (min-width: 1024px) {

    #hero .carousel-control-prev,
    #hero .carousel-control-next {
        width: 5%;
    }
}

@media (max-width: 768px),
(max-height: 700px) {
    #hero {
        height: 120vh;
        padding: 100px 0;
    }

    #hero h2 {
        font-size: 28px;
    }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg {
    background: rgb(24, 38, 98);
    background: linear-gradient(0deg, rgba(24, 38, 98, 1) 0%, rgba(37, 58, 150, 1) 100%);
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #054a85;
    font: normal normal normal 36px/40px Paytone One;
}

.section-title p {
    margin-bottom: 0;
    font-style: italic;
}

/*--------------------------------------------------------------
# Icon Boxes
--------------------------------------------------------------*/
.icon-boxes {
    padding-top: 0;
    position: relative;
    z-index: 100;

}

.icon-boxes .icon-box {
    padding: 40px 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 5px 10px 29px 0 rgba(68, 88, 144, 0.2);
    transition: all 0.3s ease-in-out;
    border-radius: 10px;

    .title {
        font: normal normal normal 25px/40px Paytone One;
    }
}

.icon-boxes .icon {
    margin: 0 auto 20px auto;
    display: inline-block;
    text-align: center;
}

.icon-boxes .icon i {
    font-size: 36px;
    line-height: 1;
    color: var(--laranja-principal);
}

.icon-boxes .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
}

.icon-boxes .title a {
    color: #05579e;
}

.icon-boxes .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
    color: #777777;
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about {
    padding: 100px 0;
    position: relative;
}

.about:before {
    content: "";
    background: rgba(255, 255, 255, 0.75);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

.about .container {
    position: relative;
}

.about .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-left: 28px;
    position: relative;
}

.about .content ul li+li {
    margin-top: 10px;
}

.about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: var(--azul-principal);
    line-height: 1;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about .content .btn-learn-more {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: var(--azul-principal);
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid var(--azul-principal);
}

.about .content .btn-learn-more:hover {
    background: var(--azul-principal);
    color: #fff;
    text-decoration: none;
}

@media (max-width: 992px) {
    .about {
        padding: 60px 0;
    }
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients .swiper-slide img {
    opacity: 0.5;
    transition: 0.3s;
}

.clients .swiper-slide:hover img {
    opacity: 1;
}

.clients .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.clients .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid var(--azul-principal);
}

.clients .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--azul-principal);
}

.clients .owl-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.clients .owl-item img {
    width: 60%;
    opacity: 0.5;
    transition: 0.3s;
}

.clients .owl-item img:hover {
    opacity: 1;
}

.clients .owl-nav,
.clients .owl-dots {
    margin-top: 5px;
    text-align: center;
}

.clients .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd !important;
}

.clients .owl-dot.active {
    background-color: var(--azul-principal) !important;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
    background: #f1f8ff;
    padding: 0;
}

.why-us .content {
    padding: 60px 100px 0 100px;
}

.why-us .content h3 {
    font-weight: 400;
    font-size: 34px;
}

.why-us .content h4 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
}

.why-us .content p {
    font-size: 15px;
    color: #848484;
}

.why-us .video-box {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 400px;
    position: relative;
}

.why-us .accordion-list {
    padding: 0 100px 60px 100px;
}

.why-us .accordion-list ul {
    padding: 0;
    list-style: none;
}

.why-us .accordion-list li+li {
    margin-top: 15px;
}

.why-us .accordion-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
}

.why-us .accordion-list a {
    display: block;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 30px;
    outline: none;
    cursor: pointer;
}

.why-us .accordion-list span {
    color: var(--azul-principal);
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
}

.why-us .accordion-list i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
}

.why-us .accordion-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

.why-us .accordion-list .icon-show {
    display: none;
}

.why-us .accordion-list a.collapsed {
    color: #343a40;
}

.why-us .accordion-list a.collapsed:hover {
    color: var(--azul-principal);
}

.why-us .accordion-list a.collapsed .icon-show {
    display: inline-block;
}

.why-us .accordion-list a.collapsed .icon-close {
    display: none;
}

.why-us .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(var(--azul-principal) 50%, rgba(8, 128, 232, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
}

.why-us .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.why-us .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(8, 128, 232, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
}

.why-us .play-btn:hover::after {
    border-left: 15px solid var(--azul-principal);
    transform: scale(20);
}

.why-us .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
}

@media (max-width: 1024px) {

    .why-us .content,
    .why-us .accordion-list {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 992px) {
    .why-us .content {
        padding-top: 30px;
    }

    .why-us .accordion-list {
        padding-bottom: 30px;
    }
}

@-webkit-keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

@keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    margin-bottom: 20px;
    padding: 50px 40px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.services .icon-box i {
    float: left;
    color: var(--laranja-principal);
    font-size: 40px;
    line-height: 0;
}

.services .icon-box h4 {
    margin-left: 70px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
}

.services .icon-box h4 a {
    color: #05579e;
    transition: 0.3s;
}

.services .icon-box h4 a:hover {
    color: var(--azul-principal);
}

.services .icon-box p {
    margin-left: 70px;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    background: linear-gradient(rgba(5, 74, 133, 0.8), rgba(5, 74, 133, 0.9)), url("../img/cta-bg.jpg") fixed center center;
    background-size: cover;
    padding: 120px 0;
}

.cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}

.cta p {
    color: #fff;
}

.cta .cta-btn {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 8px 26px;
    border-radius: 2px;
    transition: 0.5s;
    margin: 10px;
    border-radius: 50px;
    border: 2px solid var(--laranja-principal);
    color: #fff;
}

.cta .cta-btn:hover {
    background: var(--laranja-principal);
}

@media (max-width: 1024px) {
    .cta {
        background-attachment: scroll;
    }
}

@media (min-width: 769px) {
    .cta .cta-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

/*--------------------------------------------------------------
# Portfoio
--------------------------------------------------------------*/
.portfoio #portfolio-flters {
    padding: 0;
    margin: 0 auto 25px auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;
}

.portfoio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 18px 10px 18px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: #444444;
    margin: 0 3px 10px 3px;
    transition: all ease-in-out 0.3s;
    border-radius: 50px;
}

.portfoio #portfolio-flters li:hover,
.portfoio #portfolio-flters li.filter-active {
    color: #fff;
    background: var(--azul-principal);
}

.portfoio #portfolio-flters li:last-child {
    margin-right: 0;
}

.portfoio .portfolio-item {
    margin-bottom: 30px;
    overflow: hidden;
}

.portfoio .portfolio-item img {
    position: relative;
    top: 0;
    transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.portfoio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: -50px;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    background: var(--azul-principal);
    padding: 15px 20px;
}

.portfoio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
}

.portfoio .portfolio-item .portfolio-info p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0;
}

.portfoio .portfolio-item .portfolio-info .preview-link,
.portfoio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 50px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: white;
    transition: ease-in-out 0.3s;
}

.portfoio .portfolio-item .portfolio-info .preview-link:hover,
.portfoio .portfolio-item .portfolio-info .details-link:hover {
    color: #8ec8fb;
}

.portfoio .portfolio-item .portfolio-info .details-link {
    right: 15px;
}

.portfoio .portfolio-item:hover img {
    top: -30px;
}

.portfoio .portfolio-item:hover .portfolio-info {
    opacity: 1;
    bottom: 0;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
    padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
    width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid var(--azul-principal);
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--azul-principal);
}

.portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(246, 176, 36, 0.08);
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
}

.portfolio-details .portfolio-description {
    padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
    padding: 0;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
    position: relative;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 10px;
    background: #fff;
}

.team .member .pic {
    overflow: hidden;
    width: 180px;
    border-radius: 50%;
}

.team .member .pic img {
    transition: ease-in-out 0.3s;
}

.team .member:hover img {
    transform: scale(1.1);
}

.team .member .member-info {
    padding-left: 30px;
}

.team .member h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #05579e;
}

.team .member span {
    display: block;
    font-size: 15px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
}

.team .member span::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #bfe0fd;
    bottom: 0;
    left: 0;
}

.team .member p {
    margin: 10px 0 0 0;
    font-size: 14px;
}

.team .member .social {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.team .member .social a {
    transition: ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    background: #ecf6fe;
    color: #0665b7;
}

.team .member .social a i {
    font-size: 16px;
    margin: 0 2px;
}

.team .member .social a:hover {
    background: var(--azul-principal);
    color: #fff;
}

.team .member .social a+a {
    margin-left: 8px;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: relative;
    //overflow: hidden;
    border-top: 5px solid var(--azul-principal);

    hr {
        max-width: 180px;
        margin: 15px auto;
    }
}

.pricing h3 {
    font-weight: 400;
    margin: -20px -20px 20px -20px;
    padding: 20px 15px;
    font-size: 18px;
    font-weight: 700;
    color: var(--azul-principal);
    font: normal normal normal 18px/40px Paytone One;

}

.pricing h4 {
    font-size: 36px;
    color: var(--laranja-principal);
    font-weight: 600;
    margin-bottom: 20px;
}

.pricing h4 sup {
    font-size: 20px;
    top: -2px;
    left: -3px;
    font-weight: 300;
}

.pricing h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
}

.pricing ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
}

.pricing ul li {
    padding-bottom: 16px;
}

.pricing ul i {
    color: var(--azul-principal);
    font-size: 18px;
    padding-right: 4px;
}

.pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
}

.pricing .btn-wrap {
    margin: 20px -20px -20px -20px;
    padding: 20px 15px;
    text-align: center;
}

.pricing .btn-buy {
    background: var(--verde-principal);
    display: inline-block;
    padding: 10px 35px 10px 35px;
    border-radius: 50px;
    color: var(--azul-principal);
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-weight: 600;
    transition: 0.3s;

    &:hover {
        background: var(--azul-principal);
        color: var(--verde-principal);
    }
}

.pricing .featured h3 {
    color: #fff;
    background: var(--azul-principal);
}

.pricing .advanced {
    width: 95px;
    position: absolute;
    top: -12px;
    right: -10px;
    z-index: 1;
    font-size: 11px;
    background: url(../img/shape-plan.svg) no-repeat;

    line-height: 12px;
    padding: 18px 0 35px 0;
    text-transform: uppercase;
    height: 98px;

    span {
        transform: rotate(45deg);
        color: var(--unnamed-color-ffffff);
        position: absolute;
        top: 8px;
        left: 47px;
    }
}

/*--------------------------------------------------------------
# faq
--------------------------------------------------------------*/
.faq {
    h2 {
        color: var(--unnamed-color-ffffff);
    }

    .faq-list {
        i {
            color: var(--laranja-principal);
        }
    }
}


.faq .faq-list {
    padding: 0 100px;
}

.faq .faq-list ul {
    padding: 0;
    list-style: none;
}

.faq .faq-list li+li {
    margin-top: 15px;
}

.faq .faq-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
}

.faq .faq-list a {
    display: block;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px 0 0;
    outline: none;
    cursor: pointer;
}

.faq .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: #75bdfa;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
}

.faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
    display: none;
}

.faq .faq-list a.collapsed {
    color: #343a40;
}

.faq .faq-list a.collapsed:hover {
    color: var(--azul-principal);
}

.faq .faq-list a.collapsed .icon-show {
    display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
    display: none;
}

@media (max-width: 1200px) {
    .faq .faq-list {
        padding: 0;
    }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
    background: url("../img/contact-bg.png") left center no-repeat;
    position: relative;
    background: #182662;
}

.contact:before {
    content: "";
    // background: rgba(255, 255, 255, 0.7);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

.contact .container {
    position: relative;
    padding: 3%;
    background: #fff;
    border-radius: 20px;
    max-width: 970px;
}

.contact .info {
    width: 100%;
}

.contact .info i {
    font-size: 20px;
    background: var(--azul-principal);
    color: #fff;
    float: left;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
    color: #043c6d;

}

.contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #444444;
}

.contact .info .email,
.contact .info .phone {
    margin-top: 40px;
}

.contact .php-email-form {
    width: 100%;
    background: #fff;
}

.contact .php-email-form .form-group {
    padding-bottom: 8px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br+br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 4px;
    box-shadow: none;
    font-size: 14px;
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
    border: 0;
    padding: 10px 32px;
    color: var(--azul-principal);
    transition: 0.4s;
    border-radius: 50px;
    border: 2px solid var(--azul-principal);
    background: #fff;
}

.contact .php-email-form button[type=submit]:hover {
    background: var(--azul-principal);
    color: #fff;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    background: #ecf6fe;
    margin-top: 130px;


}

.breadcrumb {
    li {
        a {
            color: var(--unnamed-color) !important;
        }
    }

    .active {
        font-weight: bold;
        color: var(--azul-principal) !important;
    }
}

.breadcrumbs h2 {
    font-size: 26px;
    font-weight: 600;
    color: #043c6d;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
}

.breadcrumbs ol li+li {
    padding-left: 10px;
}

.breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #f8c255;
    content: "/";
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
    padding: 40px 0 20px 0;
}

.blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
}

.blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
    color: #054a85;
    transition: 0.3s;
}

.blog .entry .entry-title a:hover {
    color: var(--laranja-principal);
}

.blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #2b99f8;
}

.blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}

.blog .entry .entry-meta ul li+li {
    padding-left: 20px;
}

.blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
}

.blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
}

.blog .entry .entry-content p {
    line-height: 24px;
}

.blog .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
}

.blog .entry .entry-content .read-more a {
    display: inline-block;
    background: var(--laranja-principal);
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
    background: #f7b93c;
}

.blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
}

.blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
    color: #444444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: var(--azul-principal);
    margin-top: 20px;
    margin-bottom: 20px;
}

.blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
    color: #8ec8fb;
    display: inline;
}

.blog .entry .entry-footer a {
    color: #138df7;
    transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
    color: var(--laranja-principal);
}

.blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
}

.blog .entry .entry-footer .cats li {
    display: inline-block;
}

.blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
}

.blog .entry .entry-footer .tags li {
    display: inline-block;
}

.blog .entry .entry-footer .tags li+li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
}

.blog .entry .entry-footer .share {
    font-size: 16px;
}

.blog .entry .entry-footer .share i {
    padding-left: 5px;
}

.blog .entry-single {
    margin-bottom: 30px;
}

.blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
    width: 120px;
    margin-right: 20px;
}

.blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: var(--azul-principal);
}

.blog .blog-author .social-links {
    margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
    color: rgba(8, 128, 232, 0.5);
    margin-right: 5px;
}

.blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
}

.blog .blog-comments {
    margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
    font-weight: bold;
}

.blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
}

.blog .blog-comments .comment .comment-img {
    margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
    width: 60px;
}

.blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444444;
    transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
    color: var(--laranja-principal);
}

.blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: var(--azul-principal);
}

.blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
}

.blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #2b99f8;
    margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
}

.blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
}

.blog .blog-comments .reply-form p {
    font-size: 14px;
}

.blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #fad386;
}

.blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #fad386;
}

.blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: var(--azul-principal);
}

.blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #138df7;
}

.blog .blog-pagination {
    color: var(--azul-principal);
}

.blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}

.blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
}

.blog .blog-pagination li a {
    color: var(--azul-principal);
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
    background: var(--laranja-principal);
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
    color: #fff;
}

.blog .sidebar {
    padding: 30px;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #0665b7;
    position: relative;
}

.blog .sidebar .sidebar-item {
    margin-bottom: 30px;
}

.blog .sidebar .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
}

.blog .sidebar .search-form form input[type=text] {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background: var(--laranja-principal);
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
}

.blog .sidebar .search-form form button i {
    line-height: 0;
}

.blog .sidebar .search-form form button:hover {
    background: #f8c255;
}

.blog .sidebar .categories ul {
    list-style: none;
    padding: 0;
}

.blog .sidebar .categories ul li+li {
    padding-top: 10px;
}

.blog .sidebar .categories ul a {
    color: #0665b7;
    transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
    color: var(--laranja-principal);
}

.blog .sidebar .categories ul a span {
    padding-left: 5px;
    color: #aaaaaa;
    font-size: 14px;
}

.blog .sidebar .recent-posts .post-item+.post-item {
    margin-top: 15px;
}

.blog .sidebar .recent-posts img {
    width: 80px;
    float: left;
}

.blog .sidebar .recent-posts h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
    color: #0665b7;
    transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
    color: var(--laranja-principal);
}

.blog .sidebar .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #aaaaaa;
}

.blog .sidebar .tags {
    margin-bottom: -10px;
}

.blog .sidebar .tags ul {
    list-style: none;
    padding: 0;
}

.blog .sidebar .tags ul li {
    display: inline-block;
}

.blog .sidebar .tags ul a {
    color: var(--azul-principal);
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid white;
    display: inline-block;
    transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
    color: #fff;
    border: 1px solid var(--laranja-principal);
    background: var(--laranja-principal);
}

.blog .sidebar .tags ul a span {
    padding-left: 5px;
    color: white;
    font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    background: #fff;
    padding: 0 0 30px 0;
    color: var(--azul-principal);
    font-size: 14px;
    position: relative;
    overflow: hidden;
    padding-top: 100px;

    &::after {
        content: '';
        width: 130%;
        height: 340px;
        background: #182662;
        position: absolute;
        top: -273px;
        right: -15%;
        -webkit-border-bottom-right-radius: 130%;
        -webkit-border-bottom-left-radius: 130%;
        -moz-border-radius-bottomright: 130%;
        -moz-border-radius-bottomleft: 130%;
        border-bottom-right-radius: 200%;
        border-bottom-left-radius: 200%;
    }
}

#footer .footer-newsletter {
    padding: 50px 0;
    background: #05579e;
}

#footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
}

#footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
}

#footer .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    margin: 3px;
    background: var(--laranja-principal);
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
}

#footer .footer-newsletter form input[type=submit]:hover {
    background: var(--azul-principal);
}

#footer .footer-top {
    background: #fff;
    padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
    margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
    font-size: 18px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
    color: var(--azul-principal);
}

#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    color: var(--unnamed-color);
    font-family: "Roboto";
}

#footer .footer-top .social-links a {
    font-size: 16px;
    display: inline-block;
    background: var(--azul-principal);
    color: var(--unnamed-color-ffffff);
    line-height: 1;
    padding: 10px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
    background: var(--laranja-principal);
    color: var(--azul-principal);
    text-decoration: none;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: var(--azul-principal);
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #5db1f9;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: var(--azul-principal);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    color: var(--laranja-principal);
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
    line-height: 26px;
}

#footer .copyright {
    text-align: center;
    padding-top: 30px;
}

#footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #fff;
}

#footer .credits a {
    color: var(--laranja-principal);
}


//custom

//tooltip for li elements anotte
#tooltip {
    background: #eee;
    border: 1px solid #bbb;
    padding: 5px;
    position: absolute;
    max-width: 400px;
}

.nd {
    display: none;
}

//end here



.content_zip {
    h3 {
        margin-top: 20px;
        font: normal normal normal 36px/40px Paytone One;
        color: var(--unnamed-color);

        span {
            color: var(--azul-principal);
        }
    }

    p {
        color: var(--unnamed-color);
    }
}

.btn-exp {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background-color: var(--azul-principal);
    color: var(--verde-principal);
    font-weight: bold;
}

.services {
    background: url("../../img/bg_img_1.png");
    background-repeat: no-repeat;

    h3 {
        font-size: 46px;
        max-width: 740px;
        text-align: left;
        font: normal normal normal 36px/40px Paytone One;
        color: var(--azul-principal);

        span {
            color: var(--laranja-principal);
        }
    }
}

.align-items-stretch {
    ul {
        width: 100%;
        padding: 0;

        li {
            list-style-type: none;
            margin: 15px 0;
            color: var(--azul-principal);
            font-size: 28px;

            img {
                margin-right: 10px;
            }
        }
    }
}

.card-login {

    max-width: 870px;
    margin: 10% auto;
    background-color: var(--unnamed-color-ffffff);
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
    border-radius: 10px;

    .login {
        max-width: 360px;
        margin: 10% auto;

        h2 {
            font-weight: bold;
            text-align: center;
            margin: 15px 0;
            width: 100%;
            color: var(--azul-principal);

        }
    }
}

.logo-login {
    background-color: var(--azul-principal);

    h2 {
        margin: 20% 0 5% 0;
        display: block;
        font-size: 22px;
        color: var(--verde-principal);
        font-weight: bold;
        padding: 20px;
    }
}

.card-price {
    padding: 100px 0 50px 0;

    h1 {
        margin-top: 20px;
        font: normal normal normal 25px/40px Paytone One;
        text-align: left;
        font-size: 42px;
        color: var(--azul-principal);

        span {
            color: var(--verde-principal);
        }
    }

    p {
        font-size: 18px;
        font-style: normal;
        text-align: left;
    }
}

.space-line {
    width: 100%;
    margin: 30px 0;
    display: flex;

    .a {
        width: 10px;
        height: 8px;
        background: var(--verde-principal);
        margin-right: 5px;
        border-radius: 5px;
    }

    .b {
        width: 50px;
        height: 8px;
        border-radius: 5px;
        background: var(--azul-principal);


    }

}


.stepper {
    margin: 0 auto 40px auto;
    max-width: 800px;
}

// .stepper-head {
//   // flex-direction: column;
// }

.stepper-completed .stepper-head-icon {
    background: none;
    pointer-events: none;
}

.stepper-head-icon {
    padding: 26px 30px 34px 30px !important;
    padding: 30px;
    background: none;
    border: 1px dashed var(--unnamed-color);
    color: var(--unnamed-color);
    font-size: 40px;
    font: normal normal normal 40px/40px Paytone One;
}

.stepper-head:hover {
    background: none;
    cursor: default;
    // .stepper-head-icon {
    //   border: 1px dashed var(--laranja-principal);
    //   color: var(--laranja-principal);
    // }
}



.stepper-active .stepper-head-icon {
    padding: 26px 30px 34px 30px !important;
    padding: 30px;
    background: none;
    border: 1px dashed var(--unnamed-color);
    color: var(--unnamed-color);
    font-size: 40px;
    font: normal normal normal 40px/40px Paytone One;
}

.step-active .stepper-head-icon {
    padding: 26px 30px 34px 30px !important;
    padding: 30px;
    background: none;
    border: 1px dashed var(--azul-principal);
    color: var(--azul-principal);
    font-size: 40px;
    font: normal normal normal 40px/40px Paytone One;
}

.stepper:not(.stepper-vertical) .stepper-step:first-child .stepper-head {
    padding-left: 25%;
}

.stepper:not(.stepper-vertical) .stepper-step:last-child .stepper-head {
    padding-right: 25%;
}

.stepper:not(.stepper-vertical) .stepper-head-icon {
    margin: 2.5rem 1.5rem 1.5rem 1.6rem;
}

.stepper-head-text {
    position: absolute;
    bottom: -15px;
    font-family: "Roboto";
    font-size: 14px;
}

.stepper:not(.stepper-vertical) .stepper-step:not(:last-child) .stepper-head:after,
.stepper:not(.stepper-vertical) .stepper-step:not(:first-child) .stepper-head:before {
    height: 4px;
    background-color: var(--unnamed-color);
    margin-top: 5px;
}

.title-h {
    h1 {
        font: normal normal normal 42px/40px Paytone One;
        color: var(--azul-principal);
    }

    h3 {
        font-size: 20px;
        font-weight: bold;
        color: var(--unnamed-color);
        width: 100%;
        text-align: left;
    }

    p {
        font-size: 18px;
        color: var(--unnamed-color);
    }
}

.register {
    .card {
        max-width: 870px;
        margin: 0 auto;
    }
}

.toggle-password {
    position: absolute;
    right: 10px;
    top: 15px;
    color: var(--unnamed-color);
}

.lbsc {
    width: 100%;
    display: flex;

    .lbs {
        width: 200px;
        height: 40px;
        font-size: 11px;
        line-height: 16px;
        color: var(--unnamed-color);
        margin: 10px 0;
    }
}

.btn-p {
    text-align: center;

    button {
        width: 100%;
        max-width: 200px;
        margin: 20px auto;
    }
}

.check-f {
    .form-check {
        max-width: 490px;
        margin: 30px auto 15px auto;
        position: relative;

        .invalid-tooltip {
            top: 25px;
        }
    }
}

.conf-email {
    h5 {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        color: var(--azul-principal);
        margin: 40px 0;
        display: inline-block;
        width: 100%;
    }
}

.li-conf {
    max-width: 800px;
    margin: 0 auto;

    ul {
        li {
            font-size: 20px;
            color: var(--unnamed-color);
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
            line-height: normal;

            &::marker {
                color: var(--azul-principal);
                font-size: 30px;
            }
        }
    }
}

.radio-custom {
    max-width: 600px;
    margin: 0 auto;

    .btr {
        width: 100%;

        .btn-green {
            max-width: 200px;
            width: 100%;
            margin: 0 auto;
            font-weight: bold;
            border: 1px solid;
        }
    }

    .btn-check:checked+.btn-green {
        background: var(--verde-principal);
        color: var(--azul-principal);
        border-color: var(--verde-principal);


    }
}

.select,
legend {
    span {
        color: red;
    }
}

.select-wrapper {
    input {
        height: 44.92px;
    }
}

.d690 {
    max-width: 690px;
    margin: 0 auto;
}

.check-orange[type=checkbox]:checked:focus,
.check-orange[type=checkbox]:checked,
.check-orange:checked {
    background-color: var(--laranja-principal);
    border-color: var(--laranja-principal);
}

.is_me {
    max-width: 225px;
    margin: 0 auto;
}



.stepper:not(.stepper-vertical) .stepper-complete:not(:last-child) .stepper-head:after,
.stepper:not(.stepper-vertical) .stepper-complete:not(:first-child) .stepper-head:before {
    background-color: var(--verde-principal);
}

.stepper-complete {
    .stepper-head-icon {
        border: 2px solid var(--verde-principal);
        color: var(--verde-principal);
    }
}

.cards-blue {
    margin: 15px 0;

    .card-body {
        padding: 0.5rem 1.5rem;
        position: relative;

        .action-card {
            position: absolute;
            right: 6px;
            top: calc(50% - 25px);
            width: 170px;
            height: 50px;
            text-align: end;

            a {
                color: var(--laranja-principal);
                font-size: 2rem;
                border-left: 1px solid var(--azul-principal);
                padding: 0 10px;
            }

            p {
                padding: 10px 8px 0px 0;
                display: inline-block;
                position: relative;
                top: -6px;
                font-weight: bold;
                color: var(--azul-principal);
            }
        }
    }

    .card-header {
        background: var(--azul-principal);
        color: var(--unnamed-color-ffffff);
        padding: 0.4rem 1rem;
        font-weight: bold;
    }

    p {
        margin: 0;
        color: var(--unnamed-color);
    }
}

.cards-orange {
    margin: 15px 0;

    .card-body {
        padding: 0.5rem 1.5rem;
        position: relative;

        .action-card {
            position: absolute;
            right: 6px;
            top: calc(50% - 25px);
            width: 170px;
            height: 50px;
            text-align: end;

            a {
                color: var(--azul-principal);
                font-size: 2rem;
                border-left: 1px solid var(--azul-principal);
                padding: 0 10px;
            }

            p {
                padding: 10px 8px 0px 0;
                display: inline-block;
                position: relative;
                top: -6px;
                font-weight: bold;
                color: var(--azul-principal);
            }
        }
    }

    .card-header {
        background: var(--laranja-principal);
        color: var(--unnamed-color-ffffff);
        padding: 0.4rem 1rem;
        font-weight: bold;
    }

    p {
        margin: 0;
        color: var(--unnamed-color);
    }
}

div.cards-custom {
    margin: 15px 0;

    .card-body {
        padding: 0.5rem 1.5rem;
        position: relative;

        .action-card {
            position: absolute;
            right: 6px;
            top: calc(50% - 25px);
            width: 170px;
            height: 50px;
            text-align: end;

            a {
                color: var(--laranja-principal);
                font-size: 2rem;
                border-left: 1px solid var(--azul-principal);
                padding: 0 10px;
            }

            p {
                padding: 10px 8px 0px 0;
                display: inline-block;
                position: relative;
                top: -6px;
                font-weight: bold;
                color: var(--azul-principal);
            }
        }
    }

    .card-header {
        background: var(--verde-principal);
        color: var(--azul-principal);
        padding: 0.4rem 1rem;
        font-weight: bold;
    }

    p {
        margin: 0;
        color: var(--unnamed-color);
    }
}

.icons {
    text-align: center;
    margin-top: 15px;

    &.desabled {
        span {
            background: #DFDFDF;
        }
    }

    span {
        background: var(--azul-principal);
        height: 45px;
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        margin: 0 auto;

    }

    p {
        line-height: initial;
        font-weight: normal;
        font-size: 12px;
    }
}

.bdrb {
    margin: 15px 0;
    border-left: 1px solid var(--azul-principal);
    margin: 15px 0 20px 0;
}

.str {
    strong {
        color: var(--azul-principal);
    }
}

.empty {
    width: 100%;

    h2 {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: var(--azul-principal);
        margin: 15px 0;
        padding: 15px 0;
    }

}

div.cards-home {
    padding-top: 10px;
    background: rgb(243, 243, 243);
    margin: 15px 0;
    position: relative;


    .seta {
        position: absolute;
        right: -20px;
        top: calc(40% - 30px);
        width: auto;
        padding: 7px 9px 9px 9px;
        background: var(--laranja-principal);
        border-radius: 30px;

        img {
            max-width: 20px;
        }
    }

    .card-body {
        padding: 0.5rem 0.5rem;
        position: relative;

        .action-card {
            position: absolute;
            right: 6px;
            top: calc(50% - 25px);
            width: 140px;
            height: 50px;
            text-align: end;

            a {
                color: var(--laranja-principal);
                font-size: 2rem;
                border-left: 1px solid var(--azul-principal);
                padding: 0 10px;
            }

            p {
                padding: 10px 8px 0px 0;
                display: inline-block;
                position: relative;
                top: -6px;
                font-weight: bold;
                color: var(--azul-principal);
            }
        }
    }

    .card-header {
        background: var(--verde-principal);
        color: var(--azul-principal);
        padding: 0.4rem 1rem;
        font-weight: bold;
    }

    p {
        margin: 0;
        color: var(--unnamed-color);
    }
}

.form-label {
    span {
        color: red;
    }
}

.tcad {
    .btn {
        position: relative;
        top: -5px;
    }
}

.d970 {
    max-width: 970px;
    margin: 0 auto;
}

.buttons-home {
    margin: 15px auto;

    .bd-r {
        @media screen and (min-width: 768px) {
            border-right: 2px solid var(--azul-principal);
        }

        @media screen and (max-width: 768px) {
            p {
                height: 20px;
                margin: 0;
            }

        }

    }

    .bd-l {
        @media screen and (min-width: 768px) {
            border-left: 2px solid var(--azul-principal);
        }

        @media screen and (max-width: 768px) {
            p {
                height: 20px;
                margin: 0;
            }

        }

    }

    p {
        @media screen and (max-width: 768px) {
            margin: 20px 0 0 0;

        }

        height: 40px;
        width: 100%;
        font-weight: bold;
        color: var(--azul-principal);
    }

    .btn {
        display: flex;
        width: 100%;
        position: relative;
        //padding: 8px 32px 8px 8px;
        text-align: center;
        flex-direction: row;
        justify-content: center;
        min-height: 75px;
        align-items: center;
        text-transform: none;
        font-weight: bold;

        span {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 1.5rem;
            padding: 6px 14px;
            border-radius: 30px;
            background: var(--unnamed-color-ffffff);
            color: var(--azul-principal);
        }
    }
}

.card-border {
    border-top: 4px solid var(--verde-principal);

    p {
        font-weight: bold;
        margin: 10px 0 0 0;
        color: var(--unnamed-color);

    }

    .card-header {
        background: #f5f3f3;
    }
}

.nav-log {
    .navbar-nav {
        a.nav-link {
            color: var(--azul-principal);

        }
    }
}

[data-letters] {
    &:before {
        content: attr(data-letters);
        display: inline-block;
        font-size: 1em;
        width: 2.5em;
        height: 2.5em;
        line-height: 2.5em;
        text-align: center;
        border-radius: 50%;
        background: var(--azul-principal);
        vertical-align: middle;
        margin-right: 0.5em;
        color: white;
    }

}

.pr {
    background: var(--azul-principal);
    width: 2.5em;
    height: 2.5em;
    color: var(--unnamed-color-ffffff);
    overflow: hidden;
    font-weight: bold;
    text-transform: uppercase;
    padding: 7px 10px;
    float: left;
}

.name-initial {
    display: inline-block;

    p {
        float: right;
        width: 80%;
        margin: 0 !important;
        font-size: 13px;
        line-height: initial;

        span {
            color: var(--azul-principal);
        }
    }
}

.card-form {
    border-top: 4px solid var(--verde-principal);

    // padding-top: 50px;
    .container {
        max-width: 970px;
        margin: 0 auto;

        h2 {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.2rem;
            color: var(--azul-principal);
        }

        h5 {
            color: var(--laranja-principal);
            font-size: 16px;
            font-weight: bold;
            margin: 15px 0 10px 0;
            text-transform: uppercase;

            .pay {
                font-size: 26px;
                color: var(--azul-principal);
            }
        }

        hr {
            margin: 25px 0 20px 0;
            width: 100%;
            padding: 0;
        }
    }
}

.bsc {
    position: relative;
    padding-right: 30px;
    border-right: 1px solid var(--unnamed-color);
    margin-top: 10px;

    input {
        border-radius: 30px !important;
        border-color: var(--azul-principal);
        width: 100%;
    }

    span {
        position: absolute;
        right: 30px;
        top: 6px;
    }
}

.nav-log .navbar-nav a.nav-link {
    i {
        font-size: 20px;
        color: var(--unnamed-color);
    }

    .badge-notification {
        right: 0px;
        top: 4px;
    }
}

.hbt {
    p {
        color: var(--unnamed-color);
        font-size: 12px;
        margin: 15px 0 0 20px;

        span {
            background: var(--unnamed-color);
            color: var(--unnamed-color-ffffff);
            padding: 2px 6px;
            border-radius: 20px;
            font-size: 14px;
            font-weight: bold;
        }
    }
}

.form-control.hinp {
    height: calc(2.08rem + 10px);
}

.card-form {
    .card-body {
        margin: 0 auto;
        border-top: 0;
    }
}

.ch-w {
    max-width: 640px;
    margin: 30px auto;
    width: 100%;
    justify-content: center;
}

.chh {
    margin-bottom: 15px;

    .form-check-input[type=radio] {
        margin-right: 3px;
    }

    .form-check-label {
        font-size: 13px;
        margin-right: 15px;
    }
}

.no-b {
    border: none;
    box-shadow: none;
}

.invalid-tooltip {
    font-size: 11px;
    top: 32px;
}

.invalid-feedback {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    background-color: rgba(249, 49, 84, 0.9);
    border-radius: 0.25rem !important;
    color: #fff;
    font-size: 11px;
    top: 32px;
}

.btn-pront {
    min-height: 70px;
    position: relative;
    border-radius: 30px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    font-weight: bold;
    text-transform: none;
    overflow: visible;

    &.b_disabled {
        background: #DFDFDF;

        &:hover {
            cursor: not-allowed
        }

        i {
            position: absolute;
            color: var(--unnamed-color-ffffff);
            right: -10px;
            bottom: 0px;
            padding: 10px;
            background: var(--azul-principal);
            border-radius: 30px;
        }
    }

    i {
        position: absolute;
        color: var(--unnamed-color-ffffff);
        right: -10px;
        bottom: 0px;
        padding: 10px;
        background: var(--verde-principal);
        border-radius: 30px;
    }
}

.btn-pront-small {
    min-height: 70px;
    position: relative;
    border-radius: 30px;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    font-weight: bold;
    text-transform: none;
    overflow: visible;

    &.b_disabled {
        background: #DFDFDF;

        &:hover {
            cursor: not-allowed
        }

        i {
            position: absolute;
            color: var(--unnamed-color-ffffff);
            right: -10px;
            bottom: 27px;
            padding: 3px;
            background: var(--azul-principal);
            border-radius: 30px;
        }
    }

    i {
        position: absolute;
        color: var(--unnamed-color-ffffff);
        margin: 0 0 0 10px;
        bottom: 27px;
        padding: 3px;
        background: var(--verde-principal);
        border-radius: 30px;
    }
}


.imp100 {
    max-width: 100% !important;
    width: 100%;

}

.f12 {
    font-size: 12px;
}

.formsp {
    .f13 {
        font-size: 13px;
    }

    label {
        color: #707070;
    }

    .w07 {
        label {
            width: 70px;
        }
    }

    .w08 {
        label {
            width: 80px;
        }
    }

    .w10 {
        label {
            width: 80px;
        }
    }

}

.header-card {
    background: #F1F1F1;
    padding: 0 25px;

    p {
        color: var(--unnamed-color);

        strong {
            color: var(--azul-principal);
        }
    }

    .card-b {
        position: relative;
        border-radius: 10px;
        background: var(--unnamed-color-ffffff);
        padding: 15px;

        p {
            margin: 0;
            color: var(--unnamed-color);

            strong {
                color: var(--azul-principal);
            }
        }
    }
}

.card-c {
    position: relative;
    padding: 0 15px 15px 20px;
    border-left: 2px solid #DFDFDF;

    p {
        margin: 0;
        color: var(--unnamed-color);

        strong {
            color: var(--azul-principal);
        }
    }
}

.btn-pr {
    position: absolute;
    right: -20px;
    top: calc(50% - 20px);
    border-radius: 30px;
    color: var(--unnamed-color-ffffff);
    padding: 10px 13px;
    font-size: 18px;
    display: flex;
    cursor: pointer;

    &.edit {
        background: var(--unnamed-color);
        padding: 10px;
    }

    &.clos {
        background: var(--laranja-principal);
    }
}

.btn-response {
    border-radius: 20px;
    padding: 6px 20px;
    margin: 0 10px;
}

.select-option-text {
    font-size: 14px;
}

.accordion-item {
    background-color: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

html {
    height: 100%;
}

.reset_s {
    max-width: 340px;
}

.md-term {
    max-height: 600px;
    overflow-y: scroll;
}

#validapw {
    position: absolute;
    bottom: 12px;
    right: 40px;
    font-size: 12px;
    font-weight: bold;
}

.img-content {
    padding: 10px;
    border: 1px solid #b7b7b7;
    text-align: center;
    min-height: 100px;
}

.btn-receb {
    display: flex;
    margin-top: 20px;

    .btn {
        width: 48%;
        display: flex;
        margin: 1%;
        text-align: center;
        text-align: center;
        justify-content: center;

        &.btn-gray {
            background: #dfdfdf;
            color: var(--azul-principal);
        }

        .card-form .container h5,
        .card-form .container .h5 {
            color: var(--laranja-principal) !important;
        }
    }
}

.c-label {
    color: var(--unnamed-color);
}

.c-label-b {
    color: var(--unnamed-color);
    font-size: 13px;
    margin-right: 0;
}

.noMG {
    .form-check {
        padding-left: 0;
        padding-right: 15px;
    }
}

.st-sdmin {
    h5 {
        color: var(--azul-principal);
        font-size: 14px;
        margin-left: 15px;
        font-weight: bold;
    }
}

/* Set default font-family */
#container {
    font-family: "Impact";
    font-size: 18px;
    height: 375px;
}

#container #toolbar-container.ql-toolbar {
    padding: 10px;
}

.ql-snow .ql-picker.ql-font,
.ql-bubble .ql-picker.ql-font {
    width: 130px;
}

/* Set dropdown font-families */
.ql-font span[data-value="impact"]::before,
.ql-font span[data-label="Impact"]::before {
    font-family: "Impact";
}

.ql-font span[data-value="courier"]::before,
.ql-font span[data-label="Courier"]::before {
    font-family: "Courier";
}

.ql-font span[data-value="comic"]::before,
.ql-font span[data-label="Comic Sans MS"]::before {
    font-family: "Comic Sans MS";
}

.ql-font-impact {
    font-family: 'Impact';
}

.ql-font-courier {
    font-family: 'Courier';
}

.ql-font-comic {
    font-family: 'Comic Sans MS';
}


.card-body.bg-light {
    background-color: #ededed !important;
    border-radius: none;
}

.txt {
    max-width: 780px;
    margin: 0 auto;
    height: 100vh;
    padding: 15px;
    position: relative;

    p {
        strong {
            color: var(--azul-principal);
        }
    }


    h2 {
        color: var(--unnamed-color) !important;
        text-align: center;
        margin: 15px 0;
    }
}

.p_rel {
    position: relative;
}

.btn-print {
    width: 100%;
    position: absolute;
    bottom: 100px;
}

.textarea-center {
    white-space: normal;
    text-align: justify;
    -moz-text-align-last: center;
    text-align-last: center;
}

/* style sheet for "A4" printing */
// @media print and (width: 21cm) and (height: 29.7cm) {
//   @page  {
//      margin: 3cm;
//      size: A4 landscape;
//   }
// }

@page {
    size: A4;
    margin: 0.8mm 0.8mm 0.8mm 0.8mm;
}

@media print {

    html,
    body {
        width: 100%;
        height: 100%;
    }

    thead {
        display: table-header-group;
    }

    tfoot {
        display: table-footer-group;
    }

    .mtp {
        padding-top: 120px;
    }

    .mtps {
        padding-top: 200px;
    }

    body {
        margin: 0;
    }

    @page {
        margin: 0.8mm 0.8mm 0.8mm 0.8mm;

    }

    .hidp {
        display: block !important;
    }

    .btsss {
        display: none;
    }



    .card-form {
        border-top: 0px !important;
    }

    .navbar {
        display: none;

    }

    #print {
        width: 100vw;
        height: 100vh;
        page-break-after: always;
    }

    .btn-print {
        display: none !important;

    }

    .btn-pr.edit {
        display: none;
    }

    .header-card .card-b {
        background: none;
        padding: 20px 0;
    }

    @page {

        .card-form .container h5,
        .card-form .container .h5 {
            color: var(--laranja-principal);
            font-size: 16px;
            font-weight: bold;
            margin: 15px 0 10px 0;
            text-transform: uppercase;
            -webkit-print-color-adjust: exact;
        }
    }

    #header,
    #nav,
    .noprint {
        display: none;
    }

    .footer-doc {
        background-color: #F1F1F1 !important;
        -webkit-print-color-adjust: exact;

    }

    .imgDoc {
        background-color: #F1F1F1 !important;
        -webkit-print-color-adjust: exact;

    }

    .header-card p {
        margin-bottom: 0;
    }

    .page-break,
    #ql-editor {
        page-break-before: always;
    }

}

.footer-doc {
    width: 100%;
    text-align: center;
    padding: 30px 10px;
    background: #ebebeb;
    color: var(--unnamed-color);

}

.imgDoc {
    text-align: center;
    padding: 20px;
    background: #F1F1F1;
    min-height: 97px;

    img {
        max-height: 120px;
        margin: 5px;
    }
}

.cp-posi {
    max-width: 200px;
    float: right;
    position: relative;
    top: -6px;

    .select-wrapper input {
        height: 34.92px;
    }
}

.cp-posi_2 {
    max-width: 100px;
    float: right;
    position: relative;
    top: -6px;
    right: 185px;

    .select-wrapper input {
        height: 34.92px;
    }
}

.header-card_print {
    background: none;

    .card-body {
        margin: 0;
        padding: 0;
    }

    p {
        margin: 0;

        strong {
            color: var(--azul-principal);

        }
    }

}

#editor {
    p {
        margin-bottom: 0;
    }
}

.form-check-inline {
    margin-right: 0;
}

.actRecs {
    // width: 200px;
    // position: absolute;
    // top: calc(50% - 18px);
    // left: Calc(50% - 100px);
    text-align: center;
    margin-top: 10px;

    button {
        background: #c9c9c9 !important;
        font-weight: bold;
        width: 100%;
        max-width: 180px;
        margin: 15px;
    }
}

.bvd {
    border-top: 4px solid var(--verde-principal);
}

.actRecs {
    // width: 200px;
    // position: absolute;
    // top: calc(50% - 18px);
    // left: Calc(50% - 100px);
    text-align: center;
    margin-top: 10px;

    button {
        background: #c9c9c9 !important;
        font-weight: bold;
        width: 100%;
        max-width: 180px;
        margin: 15px;
    }
}

.bvd {
    border-top: 4px solid var(--verde-principal);
}


.printDiv {
    position: relative;
    width: 21cm;
    // height: 29.7cm;
    margin: 0 auto;
    color: #001028;
    background: #FFFFFF;
    font-size: 12px;
    font-family: Arial;
    padding: 125px 0;
    height: 100%;
    display: table;

    .assin {
        text-align: center;
        position: fixed;
        bottom: 55px;
        width: 100%;
        left: 0;
    }

    table.mst {
        thead {
            border: none;
        }

    }


    .page {
        page-break-after: always;

        p {
            margin: 0 5px;
        }

        table {
            thead {
                tr:nth-child(odd) {
                    background-color: #eee !important;
                }
            }

            tr:nth-child(odd) {
                background-color: #fff !important;
            }

            tr:nth-child(even) {
                background-color: #eee !important;
                ;
            }
        }
    }

    .header_print {
        p {
            margin: 0 5px !important;
        }
    }

    .text-right {
        text-align: right !important;
    }

    .clearfix:after {
        content: "";
        display: table;
        clear: both;
    }

    a {
        color: #5D6975;
        text-decoration: underline;
    }

    .text-center {
        text-align: center;
    }

    .hidp {
        display: none;
    }

    #logo {
        text-align: center;
        margin-bottom: 15px;
        background: #f3f3f3;
        position: fixed;
        top: 0;
        width: 793px;
        height: 110px;
        padding: 5px;
    }

    #logoT {
        height: 110px;
    }



    h1 {

        color: #939393;
        font-size: 2em;
        line-height: 1.4em;
        font-weight: normal;
        text-align: center;
        margin: 0 0 15px 0;
        font-weight: bold;
        text-transform: uppercase;

    }

    #project {
        float: left;
        width: 100%;
    }
    .ldo {
        h5{
            margin-left: 5px;
        }
    }

    #project span {
        color: #2b44af;
        text-align: right;
        width: 110px;
        margin-right: 10px;
        display: inline-block;
        font-weight: bold;
    }

    #company {
        float: right;
        text-align: right;
    }

    #project div,
    #company div {
        white-space: nowrap;
        line-height: 20px;
    }

    main {
        display: flex;
        width: 100%;
    }

    .sectionT {
        border: 1px solid var(--azul-principal);
        border-radius: 10px;
        display: flex;
        margin: 5px;
        padding: 5px;

    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
        float: left;
        background: #ffffff;

        thead {
            border-bottom: 1px solid #C1CED9;
        }

    }



    table th,
    table td {
        text-align: center;
    }

    table th {
        padding: 5px;
        color: var(--azul-principal);
        white-space: nowrap;
        font-weight: bold;
    }

    table .service,
    table .desc {
        text-align: left;
    }

    table td {
        padding: 6px 10px;
        text-align: left;
    }

    table td.service,
    table td.desc {
        vertical-align: top;
    }

    table td.unit,
    table td.qty,
    table td.total {
        font-size: 1.2em;
    }

    table td.grand {
        border-top: 1px solid #5D6975;
    }

    #notices .notice {
        color: var(--unnamed-color);
        font-size: 1.2em;
    }

    footer, footer.tfoot1 {
        color: var(--unnamed-color);
        width: 793px;
        position: fixed;
        bottom: 0px;
        text-align: center;
        background: #f3f3f3;
        padding: 6px;
        height: 65px;
    }

    .tfoot2 {
        height: 50px;
    }


    h3 {
        font-size: 18px;
        margin: 25px 5px 5px 5px;
        font-weight: bold;
        color: var(--unnamed-color);
        display: inline-block;
        width: 100%;
        text-align: center;
    }

    p {
        color: var(--unnamed-color);
    }

    .dateP {
        text-align: right;
        color: var(--unnamed-color);
    }

}

.spr {
    width: 100%;
    display: flex;
}

.btn-cst {
    position: absolute;
    right: 5px;
    top: 8px;
    padding: 4px 8px 1px 8px;
    font-size: 11px;
}

.h60 {
    height: 60vh;
}

.btn-pr.edit.paEdit {
    position: absolute;
    right: -20px;
    width: auto;
    top: calc(65% - 20px);
}

.btn-pr.edit.paEditc {
    position: absolute;
    right: -20px;
    width: auto;
    top: calc(65% - 35px);
}

.chec {
    .fa-check {
        color: var(--verde-principal);
    }
}

.sobre {
    p{
        font-size: 18px;
        position: relative;
        .set-o {
            position: absolute;
            top: -5px;
            left: -30px;
        }
    }
}

.missao {
    position: relative;
    max-height: 200px;
    width: 100%;
    background: linear-gradient(180deg, rgb(24, 38, 98) 0%, rgb(37, 58, 150) 100%);
    padding: 20px 0;

    h2 {
       color: var(--unnamed-color-ffffff);
        margin-bottom: 30px;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        margin: 15px 0;
        span {
            color: var(--verde-principal);
        }
    }
    p{
        text-align: center;
        color: var(--unnamed-color-ffffff);
        font-size: 22px;
        position: relative;

        .sp1 {
            color: var(--laranja-principal);
        }

        .sp2 {
            color: var(--verde-principal);
        }
    }

}

#hero.h-20 h2 {
    font-size: 40px;
}
.h-20 {
    height: 200px !important;
}

.miss {
    background: #c9c9c9 !important;
}


@media only screen and (max-width: 768px) {
    .navOff {
        .navbar {
            ul {
                display: none;
            }
            &.navbar-mobile ul {
                display: block;
                padding-top: 30px;
                li {
                    padding: 10px ;
                    a{
                        color: var(--azul-principal);
                        width: 100%;
                        text-align: center;
                        font-size: 20px;
                        display: block;
                        &.exp {
                            background-color: var(--laranja-principal);
                            color: var(--unnamed-color-ffffff);
                        }
                    }
                }
            }

        }
    }
    .logo-login {
        padding: 10px 0 30px 0;
    }

    .nmm {
        margin-top: 0 !important;
    }
    .card-login {
        .row {
            margin: 0;
        }
        .login {
            max-width: 360px;
            margin: 5% auto;
            padding: 20px;
        }
    }
    .logo-login h2, .logo-login .h2 {
        margin: 5px 0 5% 0;
    }

    .stepper:not(.stepper-vertical) .stepper-head-icon {
        margin: 0.5rem 0.5rem 0.5rem 0.6rem;
        font-size: 26px;
    }
    .stepper.stepper-mobile .stepper-step {
        margin: 0;
    }

    .stepper-mobile-footer, .stepper-mobile-head {
        display: none;
    }
    .stepper.stepper-mobile {
        min-height: 80px;
        margin: 0;
    }

    .form-check-input[type=checkbox] {

        margin-right: 2px;
    }

}

#header-search {
    position: relative;

    #search-ul {
        position: absolute;
        z-index: 9999;
        width: 650px;
        margin-top: 12px;
        font-size: 12px;
        background: #e5e5e5;
        padding: 15px;
        border-radius: 10px;
        left: 35px;
        &:after {
            content: "";
            position: absolute;
            top: -11px;
            left: 25px;
            border-bottom: 12px solid #e5e5e5;
            border-top-color: inherit;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }

    }
}

.btImg {
    width: 50%;
    text-align: center;
    a{
        img {
            transition: all .2s ease-in-out;
            width: 54px;
            margin-bottom: 5px !important;
            margin-top: 2px !important;
            &:hover {
                transform: scale(1.1);
            }

        }
        &:hover {
            color: var(--verde-principal);

        }
        span {
            font-size: 13px;
        }
    }
}

.modal-content {
    section {
        padding: 0;

    }
    .modal-footer {
        button {
            border-radius: 50px;
        }
    }
    .modal-title {
        margin-bottom: 0;
        line-height: 1.6;
        font-weight: bold;
        color: var(--azul-principal);
    }
}

.calendar-tools {
    button {
        border-radius: 50px;
    }
}

.mgeTop {
    padding-top: 100px !important;
}
.oh {
    h3{
        color: var(--azul-principal);
        margin-top: 100px;
        font-size: 40px;
    }
}
.btn-orange {
    background: var(--laranja-principal);
    font-weight: bold;
    color: var(--unnamed-color-ffffff);
}

.allD {
    display: none;
}

.dt-prot {
    max-width: 140px;
    margin-top: 10px;
}


.tbMapa {
    table {
        tr {
            td {
                text-align: center;
                color: var(--unnamed-color);
                padding: 0px 10px;
                border: 1px #dfdfdf solid;
                &.tdCheck {
                    font-weight: bold;
                    color: var(--laranja-principal);
                    font-size: 18px;
                }
            }
            th {
                white-space: initial;
                text-align: left;
            }
        }
    }
}

.styleTb.tbMapa  {
    table {
        text-align: center;
        border: 1px solid var(--azul-principal);   
        tr {
            &.trColor {
                td {
                    color: var(--unnamed-color-ffffff);
                    border-color: var(--unnamed-color-ffffff);
                }
                background-color: var(--azul-principal);
               
            }
            td {
                font-size: 12px;
                border: 1px solid var(--azul-principal);
                color: #1f1f1f;
                
                &.txtVert {
                    position: relative;
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                    white-space: nowrap;
                    -webkit-writing-mode: vertical-rl;
                    writing-mode: vertical-rl;
                    padding: 5px;
                }
            }
        }
       
    }
}

.box-dash {
    margin: 20px 5px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid var(--azul-principal);
    h4 {
        font-weight: bold;
        font-size: 16px;
        i{
            font-size: 20px;
        }
    }
    h6{
        color: var(--laranja-principal);
        font-size: 12px;
    }
}

.btn-cst-emt {
    position: absolute;
    right: 12px;
    top: 8px;
    padding: 6px 20px 5px 20px;
    font-size: 14px;
    margin-top: 4px;
}
.bg-list {
    background: #eeeeee !important;
    border-radius: 5px !important;
}

.bvb {
    border-top: 4px solid var(--azul-principal);
}

.printDiv .page table tr:nth-child(even).nbgd, .printDiv .page table thead tr:nth-child(odd).nbgd {
    background: none !important;
}

.cdp {
    display: flex;
    button {
        height: 34px;
        width: 40px;
        text-align: center;
        padding: 0;
        position: relative;
        top: 10px;
        margin-left: 5px;
    }
}

