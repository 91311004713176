// Your custom variables
:root{ 
    --white: #ffffff; 
    --orange: #f15922; 
    --orange-b:#F6821D;
    --blue:#2f88a8;
    --blue-m:#006c8f;
    --yellow:#fcaf17;  
    --red: #f15922;

  }