.dual-listbox {
    display: flex;
    flex-direction: column
}

.dual-listbox .dual-listbox__container {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap
}

.dual-listbox .dual-listbox__search {
    border: 1px solid #ddd;
    padding: 10px;
    width: 100%
}

.dual-listbox .dual-listbox__available,
.dual-listbox .dual-listbox__selected {
    -webkit-margin-before: 0;
    border: 1px solid #ddd;
    height: 300px;
    margin-top: 0;
    overflow-y: auto;
    padding: 0;
    width: 300px
}

.dual-listbox .dual-listbox__buttons {
    display: flex;
    flex-direction: column;
    margin: 0 10px
}

.dual-listbox .dual-listbox__button {
    background-color: #eee;
    border: 0;
    color: #fff;
    margin-bottom: 5px;
    padding: 10px
}

.dual-listbox .dual-listbox__button:hover {
    background-color: #ddd
}

.dual-listbox .dual-listbox__title {
    -webkit-margin-before: 1rem;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
    border-top: 1px solid #efefef;
    font-size: 120%;
    font-weight: 700;
    margin-top: 1rem;
    padding: 15px 10px
}

.dual-listbox .dual-listbox__item {
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    display: block;
    padding: 10px;
    transition: background .2s ease;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none
}

.dual-listbox .dual-listbox__item.dual-listbox__item--selected {
    background-color: rgba(8, 157, 227, .7)
}