.global-spinner {
    position: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.5);
    color: #FFF;
    display: none;
}
